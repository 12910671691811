<template>
  <div class="liu-cheng-kuang">
    <template v-if="showStatus">
      <van-steps direction="vertical" :active="getList.offset">
        <van-step v-for="vo in getList.list">
          <div>{{ vo.jie_dian_ming_cheng }}</div>
          <div>{{ vo.kai_shi_shi_jian }}</div>
        </van-step>
      </van-steps>
    </template>
    <template v-else>
      <van-steps direction="vertical" :active="getListMin.offset">
        <van-step v-for="vo in getListMin.list">
          <div>{{ vo.jie_dian_ming_cheng }}</div>
          <div>{{ vo.kai_shi_shi_jian }}</div>
        </van-step>
      </van-steps>
    </template>


    <div class="zhe-die-an-niu" v-if="isZheDie" @click="showStatus =!showStatus">
      <i class="iconfont" :class="getIcon"></i>
    </div>
  </div>
</template>

<script>
import XEUtils from "xe-utils";
import moment from "moment";
import http from "@/api/http";

export default {
  name: 'jinDuXiangXi',
  mixins: [],
  props: {
    liuCheng: [Array, null],
    min: Boolean, //true显示全部 false显示最新两条
    isZheDie: {
      type: Boolean,//是否显示折叠 true=显示
      default() {
        return true
      }
    },

  },
  data() {
    return {
      hidden: [],
      showStatus: this.min,
      offset:0,
      showList:[]
    }
  },
  mounted() {
    http.get('/portal/index/get_hidden_jie_dian').then(da => {
      if (da.code === 1) {
        _.forEach(da.data, n => {
          this.hidden.push(n.id)
        })
      }
    })
  },
  methods: {
    getWanCheng(data) {
      let offset = 0
      _.forEach(data, n => {
        if (n.jie_dian_zhuang_tai === '已结束') {
          offset += 1
        }
      })
      return data.length-offset-1
    },

  },

  computed: {
    getList() {
      //隐藏不要的
      let data = XEUtils.toStringJSON(XEUtils.toJSONString(this.liuCheng)).filter(n => !this.hidden.includes(n.ywht_xiang_mu_liu_cheng_jie_dian_id))
      data = data.map(n => {
        // n.jie_dian_zhuang_tai === '未开始'
        if(n.kai_shi_shi_jian){
          n.kai_shi_shi_jian = moment(n.kai_shi_shi_jian * 1000).format('YYYY-MM-DD HH:mm')
        }else {
          n.kai_shi_shi_jian = '……'
        }
        return n
      })
      data.reverse();
      return {
        list: data,
        offset: this.getWanCheng(data)
      }


      //过滤没有开始的
      data = XEUtils.filter(data, (n) => n.jie_dian_zhuang_tai !== '未开始')
      data = XEUtils.slice(data, 0, 2)
      return {
        list: data,
        offset: this.getWanCheng(data)
      }
    },
    getListMin() {
      //隐藏不要的
      let data = XEUtils.toStringJSON(XEUtils.toJSONString(this.liuCheng)).filter(n => !this.hidden.includes(n.ywht_xiang_mu_liu_cheng_jie_dian_id))
      data = data.map(n => {
        n.kai_shi_shi_jian = moment(n.kai_shi_shi_jian * 1000).format('YYYY-MM-DD HH:mm')
        return n
      })
      data = XEUtils.filter(data, (n) => n.jie_dian_zhuang_tai !== '未开始')
      data.reverse();

      //过滤没有开始的

      data = XEUtils.slice(data, 0, 2)
      return {
        list: data,
        offset: this.getWanCheng(data)
      }
    },

    getIcon() {
      return this.showStatus ? 'icon-shou-qi' : 'icon-xiala'
    }
  }
}
</script>

<style scoped lang="less">
.liu-cheng-kuang {
  position: relative;

  .zhe-die-an-niu {
    padding-top: 3px;
    width: 50px;
    margin: auto;
    cursor: pointer;
  }
}

</style>
