<template>
  <van-action-sheet v-model:show="pageStatus" title="我要催单">
    <div class="content">
      <van-field
          type="textarea"
          autofocus
          v-model="form.message"
          placeholder="请输入催单内容……"
      />
    </div>
    <alert-button @click="onSubmit">立即提交</alert-button>
  </van-action-sheet>
</template>

<script>
import AlertButton from "@/views/open/alertButton";
import http from "@/api/http";
import din_shi from "@/api/din_shi";
import XEUtils from "xe-utils";
import _ from 'lodash'
export default {
  name: 'CuiDan',
  components: {AlertButton},
  mixins: [],
  data() {
    return {
      form: {
        kan_ban_id: '',
        message:'',
      },
      kan_ban_id: null,
      pageStatus: false,
    }
  },
  mounted() {
  },
  methods: {
    show({kan_ban_id}) {
      this.form.kan_ban_id = kan_ban_id
      http.post('/gzhphp/user_xiang_mu/is_cui_dan',this.form).then(da=>{
        if(da.code === 1){
          this.pageStatus = true

        }
      })

    },
    onSubmit() {
      http.post('/gzhphp/user_xiang_mu/cui_dan', this.form).then(da => {
        if (da.code === 1) {
          XEUtils.clear(this.form, '')
          this.pageStatus = false
          din_shi.success(da.msg)
        }
      })
    }
  },
  watch: {},
  computed: {}
}
</script>


<style scoped>

</style>
