<template>
  <wo-de-xiang-mu :is-dai-ping-jia="true"/>
</template>
<script>
import WoDeXiangMu from '@/views/user/WoDeXiangMu.vue'
export default {
  name: 'DaiPingJia',
  components:{WoDeXiangMu}
}
</script>
