<template>
  <header-selete :to="{name:'UserHome'}" placeholder="搜索合同名称" @submit="resetList" ref="headerSelete" fixed/>
  <list-page top="50px" :page-size="7" ref="xiaLa" bottom="30px" @load="getLoad">
    <div class="xiang-mu" v-for="vo in dataList" :key="vo.id">
      <div class="xiang-mu-name">
        {{ vo.name }}
      </div>
      <div class="xiang-mu-create-time">
        创建时间：{{ vo.create_time }}
      </div>
      <div class="xiang-xi" v-for="v2 in vo.join_min_kan_ban" :key="v2.id">
        <div class="xiang-xi-name">
          项目编号：{{ v2.ywht_xiang_mu_code }}
        </div>
        <div class="xiang-xi-name">
          项目类型：{{ v2.join_min_type.name }}
        </div>
        <div class="xiang-mu-log">
          <jin-du-xiang-xi :liu-cheng="v2.join_liu_cheng_all"/>
        </div>
        <div class="xiang-edit">
          <a href="javascript:" @click="onCunDan(v2)" v-if="is_cun_dan(v2)">
            我要催单
          </a>
          <a href="javascript:" @click="eventPingJia({kan_ban_id:v2.id})" v-if="isPingJia(v2)">
            我要评价
          </a>
        </div>
      </div>
    </div>
  </list-page>
  <footer-nav fixed/>
  <cui-dan ref="cuiDan"/>
</template>

<script>
import NavHeader from "../../components/top/NavHeader";
import HeaderSelete from "../open/headerSelete";
import FooterNav from "../open/footerNav";
import http from "../../api/http";
import JinDuXiangXi from "./public/jinDuXiangXi";
import {XIANG_MU_STATUS} from "../../api/dataList/changLiang";
import CuiDan from "@/views/user/CuiDan";
import ListPage from "@/components/list/ListPage";
import moment from "moment";

export default {
  name: 'WoDeXiangMu',
  props: {
    isDaiPingJia: Boolean //true=只显示待评价的
  },
  components: {ListPage, CuiDan, JinDuXiangXi, FooterNav, HeaderSelete, NavHeader},
  mixins: [],
  data() {
    return {
      where: {
        isDaiPingJia: this.isDaiPingJia
      },
      dataList: []
    }
  },
  mounted() {
  },
  methods: {
    eventPingJia(params) {
      let pageName = this.isDaiPingJia ? 'DaiPingJia' : 'WoDeXiangMu'
      this.$router.push({
        name: 'PingLun',
        query: {runPage: pageName},
        params

      })
    },
    onCunDan({id}) {
      this.$refs.cuiDan.show({kan_ban_id: id})
    },
    pingLunSuccess() {
      this.resetList()
    },
    resetList() {
      this.$refs.xiaLa.reset();
    },
    getLoad({num, size}) {

      this.where.page = num
      this.where.data_length = size
      this.where.keyword = this.$refs.headerSelete.getKeyword()
      http.get('/gzhphp/user_xiang_mu/getXiangMuList', {params: this.where,load: false}).then(da => {
        if (da.code == 1) {
          da.data.data = da.data.data.map(n => {
            n.create_time = moment(n.create_time * 1000).format('YYYY-MM-DD')
            return n
          })
          if (num === 1) {
            this.dataList = []
          }
          this.dataList = [...this.dataList, ...da.data.data]
          this.$refs.xiaLa.endSuccess(da.data.data.length, da.data.total)
        }
      })
      // http.get('/gzhphp/user_xiang_mu/getXiangMuList', {params: this.where}).then(da => {
      //   this.$refs.xiaLa.setLoadSuccess2(da).then(({page, item}) => {
      //     //是否可以评价
      //     item = item.map(n => {
      //       n.create_time = moment(n.create_time * 1000).format('YYYY-MM-DD')
      //       return n
      //     })
      //     if (page === 1) this.dataList = item
      //     else this.dataList = [...this.dataList, ...item]
      //   })
      // })
    },
    //true = 可以现实催单按钮
    is_cun_dan(kanBan) {
      return _.get(kanBan, 'join_min_xiang_mu.status.id') == XIANG_MU_STATUS.JING_XING_ZHONG
    },
    //true = 可以评论
    isPingJia(kanBan) {
      if (kanBan?.join_min_xiang_mu?.status?.id != XIANG_MU_STATUS.WAN_CHENG) {
        //项目没完成
        return false;
      }
      if (_.toInteger(_.get(kanBan, 'join_kan_ban_ping_lun_min.id')) > 0) {
        //已经评论
        return false
      }
      return true
    }

  },
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.xiang-mu {
  background-color: @white;
  box-shadow: @yin-ying;
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;

  .xiang-mu-name {
    font-size: 16px;
    font-weight: bold;
  }

  .xiang-mu-create-time {
    color: @gray-6;
    font-size: 16px;
  }

  .xiang-mu-name, .xiang-mu-create-time {
    margin-bottom: 5px;
  }

  .xiang-edit {
    text-align: right;
    margin-top: 10px;

    a {
      margin-left: 5px;
    }
  }

  .xiang-mu-log {
    background-color: @gray-1;
    padding: 5px;
  }

  .xiang-xi {
    margin-top: 6px;
  }
}

.xiang-xi-name {
  padding-bottom: 5px;
  color: @gray-6;
  font-size: 16px;
}

.fan-zhuan {
  transform: rotateY(90deg);
}
</style>
